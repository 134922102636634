@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  background: #e5e6f0;
  -webkit-font-smoothing: antialiased;
}

body,
input,
button {
  font-family: Roboto, sans-serif;
}

header {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;

  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
}

div.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 0px;
}

div.content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

div.containerGeo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 30px;
  box-shadow: 2px solid #000;
}

div.containerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 30px;
  box-shadow: 2px solid #000;
}

div p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

div.containerInfo span {
  font-size: 18px;
}

p.ipv4 {
  font-weight: bold;
  font-size: 60px;
  color: #ff0000;
}

p.ipv6 {
  font-weight: bold;
  font-size: 40px;
  color: #000;
  text-align: center;
  margin-top: 40px;
}
